import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import Unauthorized from "./pages/unauthorized";
import Login from "./pages/auth/login";
import Dashboard from "./pages/dashboard/dashboard";
import Missing from "./pages/missing";

import RequireAuth from "./pages/RequireAuth";
import ItemTable from "./pages/item/item_table";
import CategoryTable from "./pages/category/category_table";

function App() {
  const ROLES = {
    ADMIN: 1,
    TENANT: 2,
  };
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* we want to protect these routes */}
        <Route element={<RequireAuth allowedRoles={[ROLES.TENANT]} />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/item" element={<ItemTable />} />
          <Route path="/category" element={<CategoryTable />} />
        </Route>
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
