import { createContext, useState, useContext, useRef, useEffect } from "react";
import LoadingBar from "../components/loading";
import { Toast } from "primereact/toast";

const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
  const [Loading, setLoading] = useState(false);
  const [toastData, setToast] = useState({});

  const toast = useRef(null);

  useEffect(() => {
    if (toastData.Message) {
      toast.current.show({
        severity: toastData.severity || "error",
        summary: toastData.summary || "",
        detail: toastData.Message || "",
        life: toastData.life || 2000,
      });
    }
  }, [toastData, toast]);

  return (
    <LoadingContext.Provider value={{ Loading, setLoading, setToast }}>
      <LoadingBar visible={Loading} />
      <Toast ref={toast} />
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => {
  return useContext(LoadingContext);
};

export default useLoading;
