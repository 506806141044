import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import Header from "../header/header";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { itemService } from "../../services/itemService";
import useDialog from "../../context/DialogProvider";
import { TieredMenu } from "primereact/tieredmenu";
import CusViewLayout from "./item_layout";

export default function ItemTable() {
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const { setItemVisible } = useDialog();
  const [columns, setColumns] = useState([]);

  const [cusView, setcusView] = useState(false);

  const menu = useRef(null);
  const items = [
    {
      label: "Clear filters",
      icon: "pi pi-filter-slash",
      command: () => {
        setLazyState((prevState) => ({
          ...prevState,
          page: 1,
          sortField: null,
          sortOrder: null,

          filters: resetFilter(),
        }));
        setGlobalFilterValue("");
      },
    },
    {
      label: "Save Columns",
      icon: "pi pi-save",
      command: () => {
        localStorage.setItem("item_table_columns", JSON.stringify(columns));
      },
    },
    {
      label: "Layout",
      icon: "pi pi-fw pi-file",
      command: () => {
        setcusView(true);
      },
    },
  ];

  // reset filter function
  const resetFilter = () => {
    return {
      id: { value: "", matchMode: FilterMatchMode.CONTAINS },
      code: {
        value: "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      barcode: { value: "", matchMode: FilterMatchMode.CONTAINS },
      name: { value: "", matchMode: FilterMatchMode.CONTAINS },
      "currency.ref": { value: "", matchMode: FilterMatchMode.CONTAINS },
      cost: { value: "", matchMode: FilterMatchMode.CONTAINS },
      price: { value: "", matchMode: FilterMatchMode.CONTAINS },
      "master_category.name": {
        value: "",
        matchMode: FilterMatchMode.CONTAINS,
      },
      "category.name": { value: "", matchMode: FilterMatchMode.CONTAINS },
      "sub_category.name": {
        value: "",
        matchMode: FilterMatchMode.CONTAINS,
      },
    };
  };

  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: resetFilter(),
  });

  // load columns from local storage
  useEffect(() => {
    const cols = JSON.parse(localStorage.getItem("item_table_columns"));
    if (cols) {
      setColumns(cols);
    } else {
      setColumns([
        {
          field: "id",
          header: "ID",
          headerStyle: { width: "5rem" },
          visibility: true,
        },
        {
          field: "code",
          header: "Code",
          headerStyle: { width: "10rem" },
          visibility: true,
        },
        {
          field: "barcode",
          header: "Barcode",
          headerStyle: { width: "10rem" },
          visibility: true,
        },
        {
          field: "name",
          header: "Name",
          headerStyle: { width: "20rem" },
          visibility: true,
        },
        {
          field: "currency.ref",
          header: "Currency",
          headerStyle: { width: "10rem" },
          visibility: true,
        },
        {
          field: "cost",
          header: "Cost",
          headerStyle: { width: "10rem" },
          visibility: true,
        },
        {
          field: "price",
          header: "Price",
          headerStyle: { width: "10rem" },
          visibility: true,
        },
        {
          field: "master_category.name",
          header: "Mater Category",
          headerStyle: { width: "10rem" },
          visibility: true,
        },
        {
          field: "category.name",
          header: "Category",
          headerStyle: { width: "10rem" },
          visibility: true,
        },
        {
          field: "sub_category.name",
          header: "Sub Category",
          headerStyle: { width: "10rem" },
          visibility: true,
        },
      ]);
    }
  }, []);

  let networkTimeout = null;

  useEffect(() => {
    loadLazyData();
    // eslint-disable-next-line
  }, [lazyState]);

  const loadLazyData = async () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    await itemService
      .items({
        params: {
          page: lazyState.page,
          limit: lazyState.rows,
          search: globalFilterValue,
          sort_by: lazyState.sortField,
          sort_direction: lazyState.sortOrder === -1 ? "desc" : "asc",
        },
        body: { filter: lazyState.filters },
      })
      .then((response) => {
        setTotalRecords(response.data.pagination.total);
        setCustomers(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
          <Button
            label="Options"
            icon="pi pi-bars"
            style={{ marginRight: "0.5rem" }}
            onClick={(e) => menu.current.toggle(e)}
          />

          <Button
            icon="pi pi-refresh"
            className="p-button-raised p-button-rounded"
            onClick={() => {
              setLazyState((prevState) => ({
                ...prevState,
                page: prevState.page,
              }));
            }}
          />
        </div>

        <InputText
          placeholder="Keyword Search"
          value={globalFilterValue ?? ""}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
          // add a delay to reduce the number of requests
          onKeyUp={(e) => {
            clearTimeout(networkTimeout);
            networkTimeout = setTimeout(() => {
              setLazyState((prevState) => ({
                ...prevState,
                first: 0,
                page: 1,
              }));
            }, 500);
          }}
        />
      </div>
    );
  };

  const onPage = (event) => {
    setLazyState((prevState) => ({
      ...prevState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    }));
  };

  const onSort = (event) => {
    setLazyState((prevState) => ({
      ...prevState,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    }));
  };

  const onFilter = (event) => {
    console.log(event);
    event["first"] = 0;
    setLazyState((prevState) => ({
      ...prevState,
      filters: event.filters,
    }));
    loadLazyData();
  };

  // dynamicColumns
  const dynamicColumns = columns
    .filter((col) => col.visibility)
    .map((col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          headerStyle={col.headerStyle}
          sortable
          filter
        />
      );
    });

  return (
    <>
      <CusViewLayout
        visible={cusView}
        setCusView={setcusView}
        items={columns}
      />
      <Header />
      <DataTable
        style={{
          marginTop: "1rem",
        }}
        value={customers}
        lazy
        // filterDisplay="row"
        dataKey="id"
        paginator
        first={lazyState.first}
        rows={lazyState.rows}
        rowsPerPageOptions={[10, 20, 50]}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        onFilter={onFilter}
        filters={lazyState.filters}
        loading={loading}
        // tableStyle={{ minWidth: "75rem" }}
        removableSort
        scrollable
        scrollHeight="calc(100vh - 300px)"
        stripedRows
        showGridlines
        globalFilterFields={["code", "barcode", "name"]}
        header={renderHeader()}
        columnResizeMode="expand"
        resizableColumns
        reorderableColumns
        onRowDoubleClick={(e) => {
          console.log(e.data);
          setItemVisible({
            visible: true,
            item: e.data,
          });
        }}
        onColReorder={(e) => {
          //reorder columns in the state
          const reorderedColumns = [...columns];
          const draggedColumn = reorderedColumns.splice(e.dragIndex, 1)[0];
          draggedColumn.headerStyle = columns[e.dragIndex].headerStyle; // save the headerStyle
          reorderedColumns.splice(e.dropIndex, 0, draggedColumn);
          setColumns(reorderedColumns);
        }}
        onColumnResizeEnd={(e) => {
          // update column width in the state
          const updatedColumns = [...columns];
          const index = updatedColumns.findIndex(
            (col) => col.field === e.column.props.field
          );
          updatedColumns[index].headerStyle = {
            width: `${e.element.clientWidth}px`,
          };
          setColumns(updatedColumns);
        }}
      >
        {dynamicColumns}
      </DataTable>
    </>
  );
}
