import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

export default function SupplierFillTable({
  initialSuppliers,
  theSuppliers,
  currencies,
  callBack,
}) {
  const [suppliers, setSuppliers] = useState([
    {
      // generate unique id
      row_id: Math.floor(Math.random() * 1000000),
      supplier_data: {},
      supplier: "",
      item_code: "",
      currency_data: {},
      currency: "",
      price: "",
    },
  ]);

  useEffect(() => {
    // if initial suppliers is passed or is not empty
    if (initialSuppliers && initialSuppliers.length > 0) {
      setSuppliers(initialSuppliers);
    }
  }, [initialSuppliers]);

  const columns = [
    { field: "supplier", header: "Supplier" },
    { field: "item_code", header: "Item Code" },
    { field: "currency", header: "Currency" },
    { field: "price", header: "Price" },
    { field: "remove", header: "Remove" },
  ];

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field } = e;
    rowData[field] = newValue;
    if (callBack) callBack(suppliers);
  };

  const editor = (options) => {
    const { field } = options;
    switch (field) {
      case "supplier":
        return supplierEditor(options);
      case "item_code":
        return textEditor(options);
      case "currency":
        return currencyEditor(options);
      case "price":
        return textEditor(options);
      default:
        return null;
    }
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        style={{ width: "100%" }}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  //supplier editor
  const supplierEditor = (options) => {
    const supplierOptions = Object.values(theSuppliers).map((supplier) => ({
      id: supplier.id,
      label: supplier.name,
      value: supplier,
    }));

    return (
      <Dropdown
        // value={options.value}
        filter
        options={supplierOptions}
        onChange={(e) => {
          options.editorCallback(e.value.name);

          // update supplier_id
          const index = suppliers.findIndex(
            (supplier) => supplier.row_id === options.rowData.row_id
          );
          suppliers[index].supplier_data = e.value;
          setSuppliers([...suppliers]);
        }}
        style={{ width: "100%" }}
        placeholder={options.value ? options.value : "Select a Supplier"}
        optionLabel="label"
      />
    );
  };

  //currency editor
  const currencyEditor = (options) => {
    let selectedCurrency = null;

    if (options.value) {
      selectedCurrency = currencies.find(
        (currency) => currency.ref === options.value
      );
    }

    return (
      <Dropdown
        id="currency"
        value={selectedCurrency}
        options={currencies}
        optionLabel="ref"
        onChange={(e) => {
          console.log(e);
          options.editorCallback(e.value.ref);

          // update currency
          const index = suppliers.findIndex(
            (supplier) => supplier.row_id === options.rowData.row_id
          );
          suppliers[index].currency_data = e.value;
          setSuppliers([...suppliers]);
        }}
        style={{ width: "100%" }}
        placeholder="Select a Currency"
      />
    );
  };

  // add new row
  const addNew = () => {
    setSuppliers([
      ...suppliers,
      {
        row_id: Math.floor(Math.random() * 1000000),
        supplier_data: {},
        supplier: "",
        item_code: "",
        currency: "",
        price: "",
      },
    ]);
  };

  // footer
  const footer = (
    <div
      className="p-clearfix"
      style={{
        width: "10rem",
        height: "3.5rem",
      }}
    >
      <Button icon="pi pi-plus" label="Add New" onClick={addNew}></Button>
    </div>
  );

  // remove row icon
  const removeRowIcon = (onClick) => {
    return (
      <Button
        icon="pi pi-times"
        rounded
        text
        severity="danger"
        aria-label="Cancel"
        onClick={onClick}
      />
    );
  };

  return (
    <DataTable
      value={suppliers}
      editMode="cell"
      tableStyle={{ minWidth: "50rem" }}
      footer={footer}
      showGridlines
      emptyMessage=""
    >
      {columns.map(({ field, header }) => {
        return (
          <Column
            key={field}
            field={field}
            header={header}
            style={{
              width: "25%",
              height: "3.5rem",
            }}
            body={(rowData) =>
              field === "remove"
                ? removeRowIcon(() => {
                    console.log(rowData);
                    const index = suppliers.indexOf(rowData);
                    suppliers.splice(index, 1);
                    setSuppliers([...suppliers]);
                  })
                : rowData[field]
            }
            editor={field === "remove" ? null : editor}
            onCellEditComplete={field === "remove" ? null : onCellEditComplete}
          />
        );
      })}
    </DataTable>
  );
}
