import { endPoints } from "../config/endPoints.js";
import { authHeader } from "../config/authHeader.js";
import { apiClient } from "../api/apiClient.js";

const currencies = async () => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.get(endPoints.allCurrencies, {
    headers: authHeader(),
  });
};

export const currencyService = {
  currencies,
};
