import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import useDialog from "../../context/DialogProvider";
import { categoryService } from "../../services/categoryService";
import { supplierService } from "../../services/supplierService";
import { currencyService } from "../../services/currencyService";
import { Panel } from "primereact/panel";
import "./new_item.css";
import ItemRow from "./item_row";
import SupplierFillTable from "./supplier_fill_table";
import { itemService } from "../../services/itemService";
import useLoading from "../../context/LoadingProvider";
import MyFilePond from "../../components/filePond";

//item class
class ItemClass {
  constructor(
    code,
    barcode,
    name,
    short_name,
    section,
    master_category,
    category,
    sub_category,
    currency,
    cost,
    price,
    min_stock,
    min_price,
    is_active,
    vat_group,
    suppliers,
    images
  ) {
    this.code = code;
    this.barcode = barcode;
    this.name = name;
    this.short_name = short_name;
    this.section = section;
    this.master_category = master_category;
    this.category = category;
    this.sub_category = sub_category;
    this.currency = currency;
    this.cost = cost;
    this.price = price;
    this.min_stock = min_stock;
    this.min_price = min_price;
    this.is_active = is_active;
    this.vat_group = vat_group;
    this.suppliers = suppliers;
    this.images = images;
  }

  static empty() {
    return new ItemClass(
      "",
      "",
      "",
      "",
      "",
      { name: "" },
      { name: "" },
      { name: "" },
      { ref: "" },
      "",
      "",
      "",
      "",
      true,
      { name: "" },
      [],
      []
    );
  }
}

const ItemDialog = ({ data }) => {
  const { setItemVisible } = useDialog();
  const { setLoading, setToast } = useLoading();
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [Item, setItem] = useState(ItemClass.empty());
  const filePondRef = useRef(null);

  useEffect(() => {
    if (data.visible) {
      searchCategories();
      fetchSuppliers();
      fetchCurrencies();
    }
    if (data.item) {
      //replace null values with empty string
      Object.keys(data.item).forEach((key) => {
        if (data.item[key] === null) data.item[key] = "";
      });
      setItem({
        ...data.item,
        is_active: data.item.is_active === 1 ? true : false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.visible]);

  const saveItem = (close = true) => {
    setLoading(true);
    let item = {
      code: Item.code,
      barcode: Item.barcode,
      name: Item.name,
      short_name: Item.short_name,
      currency_id: Item.currency.id ?? null,
      vat_group_id: Item.vat_group.id ?? null,
      cost: Item.cost,
      price: Item.price,
      min_qty: Item.min_stock,
      min_price: Item.min_price,
      section: Item.section,
      master_category_id: Item.master_category.id ?? null,
      category_id: Item.category.id ?? null,
      sub_category_id: Item.sub_category.id ?? null,
      is_active: Item.is_active,
      images: filePondRef?.current?.getServerId() ?? [],
    };

    console.log(item);

    itemService
      .createItem(item)
      .then((res) => {
        if (res.status === "Success") {
          setToast({
            Message: "Item created successfully",
            severity: "success",
          });
          if (close)
            setItemVisible({
              visible: false,
            });
          clearItem();
        }
      })
      .catch((error) => {
        let summary = error.message;
        let message = null;
        if (error.data.errors) {
          message = Object.keys(error.data.errors)
            .map((key) => error.data.errors[key])
            .join("\n");
        }
        setToast({
          Message: message,
          summary: summary,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateItem = (close = true) => {
    setLoading(true);
    let item = {
      id: Item.id,
      code: Item.code,
      barcode: Item.barcode,
      name: Item.name,
      short_name: Item.short_name,
      currency_id: Item.currency?.id ?? null,
      vat_group_id: Item.vat_group?.id ?? null,
      cost: Item.cost,
      price: Item.price,
      min_qty: Item.min_stock,
      min_price: Item.min_price,
      section: Item.section,
      master_category_id: Item.master_category?.id ?? null,
      category_id: Item.category?.id ?? null,
      sub_category_id: Item.sub_category?.id ?? null,
      is_active: Item.is_active,
      images: filePondRef?.current?.getServerId() ?? [],
    };
    itemService
      .updateItem(item, Item.id)
      .then((res) => {
        if (res.status === "Success") {
          if (close)
            setItemVisible({
              visible: false,
            });
          clearItem();
        }
      })
      .catch((error) => {
        let summary = error.message;
        let message = null;
        if (error.data.errors) {
          message = Object.keys(error.data.errors)
            .map((key) => error.data.errors[key])
            .join("\n");
        }
        setToast({
          Message: message,
          summary: summary,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchCategories = async () => {
    await categoryService
      .categories({
        with_pagination: 0,
      })
      .then((res) => {
        if (res) setCategories(res.data.items);
      })
      .catch((error) => {
        setToast({ Message: error.message });
      });
  };

  const fetchSuppliers = async () => {
    await supplierService
      .suppliers({
        with_pagination: 0,
      })
      .then((res) => {
        if (res) setSuppliers(res.data.items);
      })
      .catch((error) => {
        setToast({ Message: error.message });
      });
  };

  const fetchCurrencies = async () => {
    await currencyService
      .currencies()
      .then((res) => {
        if (res) setCurrencies(res.data.items);
      })
      .catch((error) => {
        setToast({ Message: error.message });
      });
  };

  const clearItem = () => {
    setItem(ItemClass.empty());
  };

  const footerContent = () => {
    if (data.item) {
      return (
        <div>
          <Button
            label="Close"
            icon="pi pi-times"
            onClick={() =>
              setItemVisible({
                visible: false,
              })
            }
            className="p-button-text"
          />
          <Button
            label="Update"
            icon="pi pi-check"
            onClick={() => {
              updateItem();
            }}
            autoFocus
          />
        </div>
      );
    }

    return (
      <div>
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() =>
            setItemVisible({
              visible: false,
            })
          }
          className="p-button-text"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={() => {
            saveItem();
          }}
          autoFocus
        />
        <Button
          label="Save/New"
          icon="pi pi-check"
          onClick={() => {
            saveItem(false);
          }}
          autoFocus
        />
      </div>
    );
  };
  return (
    <Dialog
      header="New Item"
      draggable={false}
      visible={data.visible}
      style={{ width: "80vw", height: "60vw" }}
      maximizable
      onHide={() =>
        clearItem() &
        setItemVisible({
          visible: false,
        })
      }
      footer={footerContent}
      appendTo={document.body}
    >
      <div className="flex">
        {/* general */}
        <Panel header="General" className="flex-1 mr-2">
          <table>
            <tbody>
              <ItemRow
                id={"code"}
                label={"Code"}
                value={Item.code}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    code: e.target.value,
                  }));
                }}
              />
              <ItemRow
                id={"barcode"}
                label={"Barcode"}
                value={Item.barcode}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    barcode: e.target.value,
                  }));
                }}
              />

              <ItemRow
                id={"name"}
                label={"Name"}
                value={Item.name}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    name: e.target.value,
                  }));
                }}
              />

              <ItemRow
                id={"shortName"}
                label={"Short name"}
                value={Item.short_name}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    short_name: e.target.value,
                  }));
                }}
              />
            </tbody>
          </table>
        </Panel>

        {/* category */}
        <Panel header="Category" className="flex-1 ml-2">
          <table>
            <tbody>
              <ItemRow
                id={"section"}
                label={"Section"}
                value={Item.section}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    section: e.target.value,
                  }));
                }}
              />

              <tr>
                <td>
                  <label htmlFor="masterCategory">Master Category</label>
                </td>
                <td>
                  <Dropdown
                    id="masterCategory"
                    style={{
                      marginLeft: "12px",
                      marginBottom: "8px",
                      width: "215px",
                    }}
                    value={Item.master_category}
                    onChange={(e) => {
                      setItem((previousItem) => ({
                        ...previousItem,
                        master_category: e.value,
                      }));
                    }}
                    options={categories}
                    optionLabel="name"
                    placeholder="Select a Master Category"
                    virtualScrollerOptions={{
                      itemSize: 40,
                    }}
                    filter
                    showFilterClear={true}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label htmlFor="category">Category</label>
                </td>
                <td>
                  <Dropdown
                    id="category"
                    style={{
                      marginLeft: "12px",
                      marginBottom: "8px",
                      width: "215px",
                    }}
                    value={Item.category}
                    onChange={(e) => {
                      setItem((previousItem) => ({
                        ...previousItem,
                        category: e.value,
                      }));
                    }}
                    options={categories}
                    optionLabel="name"
                    placeholder="Select a Category"
                    virtualScrollerOptions={{
                      itemSize: 40,
                    }}
                    filter
                    showFilterClear={true}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label htmlFor="subCategory">Sub Category</label>
                </td>
                <td>
                  <Dropdown
                    id="subCategory"
                    style={{
                      marginLeft: "12px",
                      marginBottom: "8px",
                      width: "215px",
                    }}
                    value={Item.sub_category}
                    onChange={(e) => {
                      setItem((previousItem) => ({
                        ...previousItem,
                        sub_category: e.value,
                      }));
                    }}
                    options={categories}
                    optionLabel="name"
                    placeholder="Select a Sub Category"
                    virtualScrollerOptions={{
                      itemSize: 40,
                    }}
                    filter
                    showFilterClear={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Panel>
      </div>

      <div className="flex">
        {/* pricing */}
        <Panel header="Pricing" className="flex-1 mr-2 mt-2">
          <table>
            <tbody>
              {/* currency */}
              <tr>
                <td>
                  <label htmlFor="currency">Currency</label>
                </td>
                <td>
                  <Dropdown
                    id="currency"
                    style={{
                      marginLeft: "12px",
                      marginBottom: "8px",
                      width: "215px",
                    }}
                    value={Item.currency}
                    onChange={(e) => {
                      setItem((previousItem) => ({
                        ...previousItem,
                        currency: e.value,
                      }));
                    }}
                    options={currencies}
                    optionLabel="ref"
                    placeholder="Select a Currency"
                    showFilterClear={true}
                  />
                </td>
              </tr>

              {/* cost */}
              <ItemRow
                id={"cost"}
                label={"Cost"}
                type="number"
                value={Item.cost}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    cost: e.target.value,
                  }));
                }}
              />

              {/* price */}
              <ItemRow
                id={"price"}
                label={"Price"}
                type="number"
                value={Item.price}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    price: e.target.value,
                  }));
                }}
              />

              {/* min stock */}
              <ItemRow
                id={"minStock"}
                label={"Min Stock"}
                type="number"
                value={Item.min_stock}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    min_stock: e.target.value,
                  }));
                }}
              />

              {/* min price */}
              <ItemRow
                id={"minPrice"}
                label={"Min Price"}
                type="number"
                value={Item.min_price}
                onChange={(e) => {
                  setItem((previousItem) => ({
                    ...previousItem,
                    min_price: e.target.value,
                  }));
                }}
              />

              {/* vat group */}
              <tr>
                <td>
                  <label htmlFor="vatGroup">VAT Group</label>
                </td>
                <td>
                  <Dropdown
                    id="vatGroup"
                    style={{
                      marginLeft: "12px",
                      marginBottom: "8px",
                      width: "215px",
                    }}
                    value={Item.vat_group}
                    onChange={(e) => {
                      setItem((previousItem) => ({
                        ...previousItem,
                        vat_group: e.value,
                      }));
                    }}
                    options={[
                      { id: 1, name: "VAT", ref: "vat" },
                      { id: 2, name: "NON VAT", ref: "non_vat" },
                    ]}
                    optionLabel="name"
                    placeholder="Select a VAT Group"
                    showFilterClear={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Panel>

        {/* status */}
        <Panel header="Options" className="flex-1 ml-2 mt-2">
          <table>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="isActive">Is Active</label>
                </td>
                <td>
                  <InputSwitch
                    style={{
                      marginLeft: "12px",
                    }}
                    checked={Item.is_active}
                    onChange={(e) => {
                      setItem((previousItem) => ({
                        ...previousItem,
                        is_active: e.value,
                      }));
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Panel>
      </div>

      <Panel header="Images" className="flex-1 mt-2">
        <MyFilePond
          files={Item.images ?? []}
          ref={filePondRef}
          allowMultiple={true}
          maxFiles={20}
        />
      </Panel>

      <Panel header="Others" className="mt-2">
        <TabView>
          <TabPanel header="Suppliers">
            <SupplierFillTable
              initialSuppliers={Item.suppliers}
              theSuppliers={suppliers}
              currencies={currencies}
              callBack={(e) => {
                setItem((previousItem) => ({
                  ...previousItem,
                  suppliers: e,
                }));
              }}
            />
          </TabPanel>
          <TabPanel header="Related Items"></TabPanel>
          <TabPanel header="Alternative Items"></TabPanel>
        </TabView>
      </Panel>
    </Dialog>
  );
};

export default ItemDialog;
