import { endPoints } from "../config/endPoints.js";
import { authHeader } from "../config/authHeader.js";
import { apiClient } from "../api/apiClient.js";

const categories = async (params) => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.get(endPoints.allCategories, {
    params: params,
    headers: authHeader(),
  });
};

const saveCategory = async (data) => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.post(endPoints.categories, data, {
    headers: authHeader(),
  });
};

const updateCategory = async (data, id) => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.patch(endPoints.categories + "/" + id, data, {
    headers: authHeader(),
  });
};

export const categoryService = {
  categories,
  saveCategory,
  updateCategory,
};
