import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // get auth from localStorage
  const storedAuth = JSON.parse(localStorage.getItem("auth")) || {};

  const [auth, setAuth] = useState(storedAuth);

  // setAuth function
  useEffect(() => {
    //set auth to localStorage
    if (auth.token) localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
