// // Import React
// import React, { forwardRef, useImperativeHandle } from "react";

// import { FilePond, registerPlugin } from "react-filepond";

// import "filepond/dist/filepond.min.css";

// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// import { server } from "../config/server";
// import useAuth from "../hooks/useAuth";

// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// const MyFilePond = forwardRef((props, ref) => {
//   let pond = null;
//   let auth = JSON.parse(localStorage.getItem("auth"));
//   let token = auth ? auth.token : "";

//   // Expose the function through the ref
//   useImperativeHandle(ref, () => ({
//     getServerId,
//   }));

//   // get serverId from filepond
//   const getServerId = () => {
//     return pond.getFiles().map((file) => file.serverId);
//   };

//   return (
//     <FilePond
//       ref={(ref) => (pond = ref)}
//       files={
//         props.files
//           ? props.files.map((file) => ({
//               // source: file.startsWith("http") ? file : server.baseAPIUrl + file,
//               source: file,
//               options: {
//                 type: "local",
//               },
//             }))
//           : []
//       }
//       allowMultiple={props.allowMultiple}
//       maxFiles={props.maxFiles}
//       acceptedFileTypes={props.acceptedFileTypes}
//       server={{
//         url: server.baseAPIUrl + server.baseAPIUrlVersion + "/upload",
//         process: {
//           method: "POST",
//           headers: {
//             Authorization: "Bearer " + token,
//           },
//           onload: (response) => {
//             props.callback(getServerId());
//             return response;
//           },
//         },
//       }}
//     />
//   );
// });

// export default MyFilePond;

// Import React
import React, { useState, forwardRef, useImperativeHandle } from "react";

import { FilePond, registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { server } from "../config/server";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const MyFilePond = forwardRef((props, ref) => {
  let pond = null;
  let auth = JSON.parse(localStorage.getItem("auth"));
  let token = auth ? auth.token : "";

  const [files, setFiles] = useState(
    props.files
      ? props.files.map((file) => ({
          source: file.image,
          options: {
            type: "local",
          },
        }))
      : []
  );

  useImperativeHandle(ref, () => ({
    getServerId,
  }));

  // get serverId from filepond
  const getServerId = () => {
    return pond.getFiles().map((file) => file.serverId);
  };

  return (
    <FilePond
      files={files}
      onupdatefiles={(files) => {
        setFiles(files);
      }}
      ref={(ref) => (pond = ref)}
      allowMultiple={props.allowMultiple}
      maxFiles={props.maxFiles}
      acceptedFileTypes={props.acceptedFileTypes}
      server={{
        url: server.baseAPIUrl + server.baseAPIUrlVersion + "/upload",
        process: {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      }}
    />
  );
});

export default MyFilePond;
