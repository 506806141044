import { ProgressBar } from "primereact/progressbar";

const LoadingBar = ({ visible }) => {
  if (visible) {
    return (
      <div className="loading">
        <ProgressBar
          mode="indeterminate"
          style={{ height: "6px" }}
        ></ProgressBar>
      </div>
    );
  } else {
    return <></>;
  }
};

export default LoadingBar;
