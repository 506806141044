export function authHeader() {
  let auth = JSON.parse(localStorage.getItem("auth"));
  let token = auth ? auth.token : {};
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {};
  }
}
