import { InputText } from "primereact/inputtext";

export const ItemRow = ({ id, label, value, type = "text", onChange }) => {
  return (
    <tr>
      <td>
        <label htmlFor={id}>{label}</label>
      </td>
      <td>
        <InputText
          className="InputField"
          id={id}
          value={value}
          onChange={onChange}
          // text type (text, password, email, url, tel, search,number)
          type={type}
        />
      </td>
    </tr>
  );
};

export default ItemRow;
