import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import "./new_item.css";

const CusViewLayout = ({ visible, setCusView, items }) => {
  const [selectedItems, setSelectedItems] = React.useState([]);

  useEffect(() => {
    const localItems = items;
    if (localItems) {
      setSelectedItems(localItems.filter((el) => el.visibility === true));
    }
  }, [items]);

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setCusView(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => onSubmit()}
        autoFocus
      />
    </div>
  );

  const onSubmit = () => {
    //Loop over items
    //If item is not selected, set visibility to false else set visibility to true

    items.forEach((item) => {
      if (selectedItems.some((el) => el.field === item.field)) {
        item.visibility = true;
      } else {
        item.visibility = false;
      }
    });
    localStorage.setItem("item_table_columns", JSON.stringify(items));

    // console.log(selectedItems);
    setCusView(false);
  };

  const onItemChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems(selectedItems.filter((el) => el.field !== value.field));
    }
  };

  return (
    <Dialog
      header="Customize View"
      draggable={false}
      visible={visible}
      style={{ width: "25vw", height: "35vw" }}
      onHide={() => setCusView(false)}
      footer={footerContent}
    >
      <div className="flex flex-column gap-3">
        {items.map((item) => {
          return (
            <div className="flex align-items-center" key={item.field}>
              <Checkbox
                inputId={item.field}
                name="item"
                value={item}
                onChange={onItemChange}
                checked={selectedItems.some((el) => el.field === item.field)}
              />
              <label className="ml-2">{item.header}</label>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
};

export default CusViewLayout;
