import { endPoints } from "../config/endPoints";
import { authHeader } from "../config/authHeader";
import { apiClient } from "../api/apiClient";

const login = async (user, pwd) => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.post(
    endPoints.login,
    JSON.stringify({
      username: user,
      password: pwd,
    })
  );
};

const logout = async () => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.get(endPoints.logout, {
    headers: authHeader(),
  });
};

export const authService = {
  login,
  logout,
};
