import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import useAuth from "../../hooks/useAuth";
import "./Login.css";
import { authService } from "../../services/authService";
import useLoading from "../../context/LoadingProvider";

const Login = () => {
  const { auth, setAuth } = useAuth();
  const { setLoading, setToast } = useLoading();
  const [rememberChecked, setRememberChecked] = useState(false);

  const navigate = useNavigate();

  const userRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");

  useEffect(() => {
    userRef.current.focus();
    if (auth.token) {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate, userRef, auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await authService
      .login(user, pwd)
      .then((response) => {
        if (response.status === "Success") {
          setAuth({
            token: response.data.token,
            user: response.data.user,
            remember: rememberChecked,
          });
          setToast({ Message: "Login successful", severity: "success" });
          navigate("/dashboard", { replace: true });
        } else {
          setToast({ Message: response.message });
        }
      })
      .catch((error) => {
        setToast({ Message: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleForgotPasswordClick = () => {
  //   navigate("/forgot-password", { replace: true });
  // };

  return (
    <>
      <div className="split left">
        <div className="centered">
          <h1
            style={{
              fontSize: "4rem",
              fontWeight: "bold",
              color: "white",
              textShadow: "2px 2px 4px #000000",
              marginBottom: "0",
              userSelect: "none",
            }}
          >
            Brain Lite
          </h1>
          <p
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "white",
              textShadow: "2px 2px 4px #000000",
              marginTop: "0",
              userSelect: "none",
            }}
          >
            Lightweight System Management
          </p>
        </div>
      </div>
      <div className="split right">
        <div className="centered">
          <div className="wrapper">
            <div className="form-wrapper">
              <h2>Login</h2>
              <form onSubmit={handleSubmit} noValidate>
                <div className="username">
                  <label htmlFor="username">Username</label>
                  <input
                    placeholder="Enter your username"
                    className="loginInput"
                    type="username"
                    name="username"
                    id="username"
                    ref={userRef}
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                  />
                </div>
                <div className="password">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="loginInput"
                    placeholder="Enter your password"
                    name="password"
                    id="password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    required
                  />
                </div>
                <div className="checkbox-forgot">
                  <div>
                    <Checkbox
                      inputId="remember"
                      onChange={(e) => {
                        setRememberChecked(e.checked);
                      }}
                      checked={rememberChecked}
                    />
                    <label htmlFor="remember" className="remember-label">
                      Remember me
                    </label>
                  </div>
                  {/* <a
                    href="#"
                    onClick={handleForgotPasswordClick}
                    className="forgot-link"
                  >
                    Forgot Password?
                  </a> */}
                </div>
                <div className="submit">
                  <Button
                    className="loginBtn"
                    severity="info"
                    raised
                    label="Login"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
