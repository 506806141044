import { server } from "./server";

export const endPoints = {
  tokenUrl: "/sanctum/csrf-cookie",
  login: server.baseAPIUrlVersion + "/auth/login",
  logout: server.baseAPIUrlVersion + "/auth/logout",
  items: server.baseAPIUrlVersion + "/items",
  allItems: server.baseAPIUrlVersion + "/items/all",
  test: server.baseAPIUrlVersion + "/test",
  categories: server.baseAPIUrlVersion + "/categories",
  allCategories: server.baseAPIUrlVersion + "/categories/all",
  suppliers: server.baseAPIUrlVersion + "/suppliers",
  allSuppliers: server.baseAPIUrlVersion + "/suppliers/all",
  currencies: server.baseAPIUrlVersion + "/currencies",
  allCurrencies: server.baseAPIUrlVersion + "/currencies/all",
};
