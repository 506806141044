import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import useDialog from "../../context/DialogProvider";
import { Panel } from "primereact/panel";
import ItemRow from "../item/item_row";
import { categoryService } from "../../services/categoryService";
import useLoading from "../../context/LoadingProvider";

import "./category.css";

class CategoryClass {
  constructor(id = null, name = "") {
    this.id = id;
    this.name = name;
  }
}

const CategoryDialog = ({ data }) => {
  const { setCategoryVisible } = useDialog();
  const [Category, setCategory] = useState(new CategoryClass(null));
  const { setLoading, setToast } = useLoading();

  useEffect(() => {
    if (data.category) {
      setCategory(data.category);
    }
  }, [data.category]);

  //save category
  const saveCategory = async (close = true) => {
    setLoading(true);
    await categoryService
      .saveCategory(Category)
      .then((response) => {
        if (response.status === "Success") {
          setToast({ Message: "Save successful", severity: "success" });
          if (close) {
            setCategoryVisible({ visible: false });
          }
          setCategory(new CategoryClass(null));
        } else {
          setToast({ Message: "Save failed", severity: "error" });
        }
      })
      .catch((error) => {
        setToast({
          Message: error?.message ?? "Save failed",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //update category
  const updateCategory = async (close = true) => {
    setLoading(true);
    await categoryService
      .updateCategory(Category, Category.id)
      .then((response) => {
        if (response.status === "Success") {
          setToast({ Message: "Update successful", severity: "success" });
          if (close) {
            setCategoryVisible({ visible: false });
          }
          setCategory(new CategoryClass(null));
        } else {
          setToast({ Message: "Update failed", severity: "error" });
        }
      })
      .catch((error) => {
        setToast({
          Message: error?.message ?? "Update failed",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerContent = () => {
    if (data.category) {
      return (
        <div>
          <Button
            label="Close"
            icon="pi pi-times"
            onClick={() =>
              setCategoryVisible({
                visible: false,
              })
            }
            className="p-button-text"
          />
          <Button
            label="Update"
            icon="pi pi-check"
            onClick={() => {
              updateCategory();
            }}
            autoFocus
          />
        </div>
      );
    }

    return (
      <div>
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() =>
            setCategoryVisible({
              visible: false,
            })
          }
          className="p-button-text"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={() => {
            saveCategory();
          }}
          autoFocus
        />
        <Button
          label="Save/New"
          icon="pi pi-check"
          onClick={() => {
            saveCategory(false);
          }}
          autoFocus
        />
      </div>
    );
  };

  return (
    <Dialog
      header="New Category"
      draggable={false}
      visible={data.visible}
      style={{ width: "50vw", height: "70vh" }}
      maximizable
      onHide={() => setCategoryVisible({ visible: false })}
      footer={footerContent()}
    >
      <div className="flex">
        {/* general */}
        <Panel header="General" className="flex-1 mr-2">
          <table>
            <tbody>
              <ItemRow
                id={"name"}
                label={"Name"}
                value={Category.name}
                onChange={(e) => {
                  setCategory((previousItem) => ({
                    ...previousItem,
                    name: e.target.value,
                  }));
                }}
              />
            </tbody>
          </table>
        </Panel>
      </div>
    </Dialog>
  );
};

export default CategoryDialog;
