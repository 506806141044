import { endPoints } from "../config/endPoints.js";
import { authHeader } from "../config/authHeader.js";
import { apiClient } from "../api/apiClient";

const items = async ({ params, body }) => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.get(endPoints.allItems, {
    params: params,
    body: body,
    headers: authHeader(),
  });
};

const test = async () => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.post(endPoints.test, {
    headers: authHeader(),
  });
};

const createItem = async (data) => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.post(endPoints.items, data, {
    headers: authHeader(),
  });
};

const updateItem = async (data, id) => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.patch(endPoints.items + "/" + id, data, {
    headers: authHeader(),
  });
};

export const itemService = {
  items,
  test,
  createItem,
  updateItem,
};
