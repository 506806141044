import { endPoints } from "../config/endPoints.js";
import { authHeader } from "../config/authHeader.js";
import { apiClient } from "../api/apiClient.js";

const suppliers = async (params) => {
  await apiClient.get(endPoints.tokenUrl);
  return apiClient.get(endPoints.allSuppliers, {
    params: params,
    headers: authHeader(),
  });
};

export const supplierService = {
  suppliers,
};
