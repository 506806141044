import { createContext, useState, useContext } from "react";
import ItemDialog from "../pages/item/new_item_dialog";
import CategoryDialog from "../pages/category/category_dialog";

const DialogContext = createContext({});

export const DialogProvider = ({ children }) => {
  const [itemVisible, setItemVisible] = useState({
    visible: false,
  });
  const [categoryVisible, setCategoryVisible] = useState({
    visible: false,
  });

  return (
    <DialogContext.Provider value={{ setItemVisible, setCategoryVisible }}>
      {children}
      <ItemDialog data={itemVisible} />
      <CategoryDialog data={categoryVisible} />
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  return useContext(DialogContext);
};

export default useDialog;
