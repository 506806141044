import axios from "axios";
import { server } from "../config/server";

const apiClient = axios.create({
  baseURL: server.baseAPIUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

apiClient.interceptors.response.use(
  (response) => {
    return handleResponse(response);
  },
  (error) => {
    // console.log("error in apiClient", error);
    //Logout if unauthenticated
    if (error.response && [401, 419].includes(error.response.status)) {
      localStorage.removeItem("auth");
      // eslint-disable-next-line no-restricted-globals
      location.replace("/login");
      // location.reload(true);
    }
    return handleError(error);
  }
);

const handleError = (error) => {
  //TODO: Handle network error (XMLHttpRequest)

  if (error.response === undefined) return Promise.reject(error);
  return Promise.reject(error.response.data);
};

const handleResponse = (response) => {
  return Promise.resolve(response.data);
};

export { apiClient };
