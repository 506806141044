import { useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { authService } from "../../services/authService";
import useAuth from "../../hooks/useAuth";
import useLoading from "../../context/LoadingProvider";
import useDialog from "../../context/DialogProvider";
import { Avatar } from "primereact/avatar";
import React, { useRef } from "react";
import { Menu } from "primereact/menu";

const Header = () => {
  const { auth, setAuth } = useAuth();
  const { setItemVisible, setCategoryVisible } = useDialog();
  const { setLoading, setToast } = useLoading();
  const menuLeft = useRef(null);

  const navigate = useNavigate();

  const handleLogout = async () => {
    setLoading(true);
    await authService
      .logout()
      .then((response) => {
        if (response.status === "Success") {
          setAuth({});
          localStorage.removeItem("auth");
          setToast({ Message: "Logout successful", severity: "success" });
          navigate("/login", { replace: true });
        } else {
          setToast({ Message: "Logout failed", severity: "error" });
        }
      })
      .catch((error) => {
        setToast({
          Message: error?.message ?? "Logout failed",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const items = [
    {
      // dashboard
      label: "Dashboard",
      icon: "pi pi-fw pi-home",
      command: () => {
        navigate("/dashboard", { replace: false });
      },
    },
    {
      label: "Item",
      icon: "pi pi-fw pi-file",
      items: [
        //item table
        {
          label: "Table",
          icon: "pi pi-fw pi-table",
          command: () => {
            navigate("/item", { replace: false });
          },
        },
        {
          label: "New",
          icon: "pi pi-fw pi-plus",
          command: () => {
            setItemVisible({
              visible: true,
            });
          },
        },
        {
          separator: true,
        },
        {
          label: "Export",
          icon: "pi pi-fw pi-external-link",
        },
      ],
    },
    //category
    {
      label: "Category",
      icon: "pi pi-fw pi-tags",
      items: [
        //item table
        {
          label: "Table",
          icon: "pi pi-fw pi-table",
          command: () => {
            navigate("/category", { replace: false });
          },
        },
        {
          label: "New",
          icon: "pi pi-fw pi-plus",
          command: () => {
            setCategoryVisible({
              visible: true,
            });
          },
        },
      ],
    },
  ];

  const profile = [
    {
      label: "Logout",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        handleLogout();
      },
    },
  ];

  const end = (
    <>
      <Menu model={profile} popup ref={menuLeft} id="popup_menu_left" />
      <Avatar
        label={auth?.user?.abbrev ?? ""}
        size="large"
        shape="circle"
        onClick={(event) => menuLeft.current.toggle(event)}
      />
    </>
  );

  return (
    <Menubar
      model={items}
      end={end}
      style={{
        position: "sticky",
        top: "0",
        zIndex: "100",
      }}
    />
  );
};

export default Header;
