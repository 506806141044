import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Layout = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();

  useEffect(() => {
    if (!auth.token) navigate("/login", { replace: true });
  }, [navigate, auth]);

  return (
    <main className="App">
      <Outlet />
    </main>
  );
};

export default Layout;
